import React from "react";
import logo from "../images/logo-footer.png";
import instagram from "../images/instagram.svg";
import twitter from "../images/twitter.svg";
import telegram from "../images/telegram.svg";

export default function Footer() {
  return (
    <footer className="footer">
      <img src={logo} alt="" />
      <div className="social">
        <a
          href="https://www.instagram.com/numismagenesis/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="" />
        </a>
        <a
          href="https://twitter.com/numismagenesis"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="" />
        </a>
        <a href="https://t.me/numismagenesis" target="_blank" rel="noreferrer">
          <img src={telegram} alt="" />
        </a>
      </div>
      <small>
        <a href="mailto:hello@numismagenesis.com">hello@numismagenesis.com</a>
      </small>
      <p className="copy">
        Nu’Misma © {new Date().getFullYear()} All rights reserved.
      </p>
    </footer>
  );
}
