import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import logo from "../images/numisma.png";
import Burger from "../components/Burger";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import whitepaper from "../images/NuMisma_Whitepaper.pdf";

export default function Header() {
  const [state, setState] = useState({
    initial: false,
    clicked: null,
  });

  // Toggle menu
  const handleMenu = () => {
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
      });
    }
  };

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".header");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  return (
    <>
      <header className={`header ${state.clicked}`}>
        <Link to="/" className="header__logo">
          <img src={logo} alt="" />
        </Link>
        <nav className="header__nav">
          <ul>
            <li>
              <AnchorLink to="/#experience">Experience</AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#tokenomics">Tokenomics</AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#roadmap">Roadmap</AnchorLink>
            </li>
            <li>
              <Link to="mailto:hello@numismagenesis.com">Contact</Link>
            </li>
            <li>
              <a
                href={whitepaper}
                target="_blank"
                className="whitepaper"
                rel="noreferrer"
              >
                WHITEPAPER
              </a>
            </li>
          </ul>
          <button className={`burger ${state.clicked}`} onClick={handleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </nav>
      </header>
      <Burger state={state} setState={setState} />
    </>
  );
}
