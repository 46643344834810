import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Link } from "gatsby";
import whitepaper from "../images/NuMisma_Whitepaper.pdf";

export default function Burger({ state, setState }) {
  let burgerMenu = useRef(null);
  const turnOff = () => {
    setState({
      clicked: !state.clicked,
    });
  };
  useEffect(() => {
    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      gsap.to(burgerMenu, { duration: 0.2, css: { display: "none" } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      gsap.to(burgerMenu, { duration: 0, css: { display: "block" } });
    }
  }, [state]);

  return (
    <div className="menu-container" ref={(el) => (burgerMenu = el)}>
      <ul className="main-menu">
        <li>
          <Link onClick={turnOff} to="#experience">
            Experience
          </Link>
        </li>
        <li>
          <Link onClick={turnOff} to="#tokenomics">
            Tokenomics
          </Link>
        </li>
        <li>
          <Link onClick={turnOff} to="#roadmap">
            Roadmap
          </Link>
        </li>
        <li>
          <Link onClick={turnOff} to="#">
            Contact
          </Link>
        </li>
        <li>
          <Link onClick={turnOff} to={whitepaper} className="whitepaper">
            WHITEPAPER
          </Link>
        </li>
      </ul>
    </div>
  );
}
